import { useEffect, useMemo, useState } from 'react'

import { usePlaygroundContext } from './PlaygroundProvider'

import type { Field } from './types'

interface FieldResponse {
  fields: Omit<Field, 'value'>[]
  identifier: string
}

/**
 *
 */
export function usePlaygroundData(url: string) {
  const [{ data: fieldCache, initiator }, { updateFieldCache }] =
    usePlaygroundContext()
  const [loading, setLoading] = useState(true)
  const [fields, setFields] = useState<Omit<Field, 'value'>[]>([])
  const [identifier, setIdentifier] = useState('')
  const [result, setResult] = useState<JSON | string | null>(null)

  useEffect(() => {
    const fetchFields = async () => {
      const response = await fetch(url)

      const { fields, identifier } = (await response.json()) as FieldResponse
      setIdentifier(identifier)
      setFields(fields)
      setLoading(false)
    }

    void fetchFields()
  }, [url, initiator])

  return useMemo(
    () => ({
      fields: fields.map((field) => ({
        ...field,
        value:
          field.key in fieldCache
            ? fieldCache[field.key]
            : field.type === 'multiselect'
            ? []
            : field.defaultValue,
      })) as Field[],
      identifier,
      handleFieldChange: updateFieldCache,
      handleSubmit: async (values: Record<string, unknown>) => {
        setLoading(true)

        const params = Object.keys(values).reduce((result, key) => {
          const field = fields.find(({ key: fieldKey }) => key === fieldKey)

          let value: string | number | string[]
          switch (field?.type) {
            case 'multiselect':
              value = values[key] as string[]
              break
            case 'number':
              value = parseInt(values[key] as string)
              break
            default:
              value = values[key] as string
          }

          updateFieldCache(key, value)

          return {
            ...result,
            [key]: value,
          }
        }, {})

        const response = await fetch(url + `?initiator=${initiator}`, {
          method: 'POST',
          body: JSON.stringify(params),
        })

        setLoading(false)
        if (response.headers.get('content-type') === 'text/plain') {
          setResult(await response.text())
        } else {
          setResult((await response.json()) as JSON)
        }
      },
      result,
      loading,
      handleRetry: () => {
        setResult(null)
      },
    }),
    [
      fields,
      fieldCache,
      loading,
      result,
      updateFieldCache,
      url,
      identifier,
      initiator,
    ],
  )
}
