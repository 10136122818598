'use client'

import type { FC } from 'react'

import { ButtonCard, CWIcon } from '@features/common.components'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import useTranslation from 'next-translate/useTranslation'

const tutorials = ['DCW', 'UCW', 'CCTP'] as const
type Tutorial = (typeof tutorials)[number]

type TutorialEntry = {
  iconColor: string
  iconBGColor: string
  tag: string
  title: string
  description: string
  link: string
}

interface TutorialsProps {
  selected?: Tutorial[]
}

export const Tutorials: FC<TutorialsProps> = ({ selected = tutorials }) => {
  const migrationEnabled = useFeatureFlag('migrationEnabled')

  const { t } = useTranslation('quickstart')

  const entries: Record<Tutorial, TutorialEntry> = {
    UCW: {
      iconColor: 'text-green-400',
      iconBGColor: 'bg-green-50',
      tag: t('tutorials.ucw.tag'),
      title: t('tutorials.ucw.title'),
      description: t('tutorials.ucw.description'),
      link: migrationEnabled
        ? t('tutorials.ucw.migratedLink')
        : t('tutorials.ucw.link'),
    },
    DCW: {
      iconColor: 'text-green-400',
      iconBGColor: 'bg-green-50',
      tag: t('tutorials.dcw.tag'),
      title: t('tutorials.dcw.title'),
      description: t('tutorials.dcw.description'),
      link: migrationEnabled
        ? t('tutorials.dcw.migratedLink')
        : t('tutorials.dcw.link'),
    },
    CCTP: {
      iconColor: 'text-violet-400',
      iconBGColor: 'bg-violet-50',
      tag: t('tutorials.cctp.tag'),
      title: t('tutorials.cctp.title'),
      description: t('tutorials.cctp.description'),
      link: migrationEnabled
        ? t('tutorials.cctp.migratedLink')
        : t('tutorials.cctp.link'),
    },
  } satisfies Record<Tutorial, TutorialEntry>

  const selectedEntries = selected.map((tutorial) => entries[tutorial])

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-8">
      {selectedEntries.map((entry) => (
        <ButtonCard
          key={entry.title}
          description={entry.description}
          href={entry.link}
          icon={{
            name: 'CodeOutline',
            iconStyle: {
              iconColor: entry.iconColor,
              iconBGColor: entry.iconBGColor,
            },
          }}
          tag={entry.tag}
          title={entry.title}
        >
          <div className="inline-flex items-center space-x-2 rounded-sm py-2 pr-1 text-blue-400 font-circular-bold hover:bg-blue-50">
            <div>Start Tutorial</div>
            <CWIcon className="h-4 w-4" name="ArrowRightSolid" />
          </div>
        </ButtonCard>
      ))}
    </div>
  )
}
