import { useMemo } from 'react'

import { Link } from '@features/common.components'
import get from 'lodash.get'

import { CodePanel } from '../../Code'
import { usePlaygroundContext } from '../PlaygroundProvider'

import type { CopyParams } from '../Playground'

export interface ResultProps {
  result: object | string
  handleRetry: () => void
  /**
   * Config for isolated copy values.
   */
  copy?: CopyParams
  identifier: string
}
export const Result: React.FC<ResultProps> = ({
  copy,
  handleRetry,
  result,
  identifier,
}) => {
  const [, { updateFieldCache }] = usePlaygroundContext()

  useMemo(() => {
    const keysToSave: Record<string, string> = {}
    copy?.params.forEach((param) => {
      if (param.key && typeof result === 'object' && 'response' in result) {
        keysToSave[param.key] = get(result.response, param.path) as string
      }
    })
    Object.entries(keysToSave).forEach(([key, value]) => {
      updateFieldCache(key, value)
    })
  }, [copy, result, updateFieldCache])

  const copyParams = useMemo(() => {
    return copy?.params
      .map((param) => {
        const value = get(
          typeof result !== 'string' && 'response' in result
            ? result.response
            : result,
          param.path,
        ) as string

        if (!value) return null

        return { code: value, title: param.title }
      })
      .filter(Boolean)
  }, [copy?.params, result])

  return (
    <>
      <button
        className="absolute right-0 top-0 mr-5 mt-2 rounded-sm px-4 py-1 text-sm text-blue-25 font-circular-bold hover:bg-white/10"
        onClick={handleRetry}
      >
        Retry
      </button>
      {typeof result !== 'string' &&
      'request' in result &&
      'response' in result ? (
        <div className="px-6 pt-4">
          <p className="mb-2">
            This is the request payload, an encapsulated set of your input
            parameters including any pertinent complex derivatives:
          </p>
          <CodePanel
            code={JSON.stringify(result.request, null, 2)}
            identifier={identifier}
            language="json"
            title="API Request"
          />

          <p className="mb-2">This is the response from our APIs:</p>
          <CodePanel
            code={JSON.stringify(result.response, null, 2)}
            identifier={identifier}
            language="json"
            title="API Response"
          />
        </div>
      ) : (
        <CodePanel
          code={
            typeof result !== 'string'
              ? JSON.stringify(result, null, 2)
              : result
          }
          identifier={identifier}
          language="json"
          embedded
        />
      )}

      {copyParams && copyParams.length > 0 ? (
        <div className="px-6">
          <p className="mb-4">
            The following parameter(s) will be relevant for the next steps. You
            can either store them or use your{' '}
            <Link path="/logs" type="console">
              API logs
            </Link>{' '}
            in the Web3 Services Console to look them up later on.
          </p>
          {copyParams.map((param) => {
            if (!param) return null
            return (
              <CodePanel
                key={param.title}
                code={param.code}
                identifier={`${identifier} - ${param.title}`}
                language="text"
                title={param.title}
              />
            )
          })}
        </div>
      ) : null}
    </>
  )
}
