'use client'

import type { FC } from 'react'
import { useCallback, useState } from 'react'

import { CWButton } from '@features/common.components'
import { FeedbackDocument } from '@features/documentation.graphql'
import { useMutation } from '@shared/apollo'
import { uuid } from '@shared/utils'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { FeedbackText } from './FeedbackText/FeedbackText'
import { ThumbsDown } from './Icons/ThumbsDown'
import { ThumbsUp } from './Icons/ThumbsUp'

interface PageFeedbackProps {
  url: string
}

export const PageFeedback: FC<PageFeedbackProps> = ({ url }) => {
  const [identifier] = useState(uuid())

  const { t } = useTranslation('documentation')
  const [positiveFeedback, setPositiveFeedback] = useState<boolean | null>(null)

  const [sendFeedback] = useMutation(FeedbackDocument, { onError: () => {} })

  const handlePositiveFeedback = useCallback(
    (value: boolean | null) => () => {
      setPositiveFeedback(value)
      if (value != null) {
        void sendFeedback({
          variables: {
            input: {
              identifier,
              actionType: 'rating',
              action: value ? 'positive' : 'negative',
              url,
            },
          },
        })
      }
    },
    [identifier, sendFeedback, url],
  )

  return (
    <div className="w-112">
      <div className="flex items-center mb-2">
        <div className="mr-2">{t`feedback.helperText`}</div>
        <CWButton
          className={classNames('text-neutral normal-case', {
            'bg-primary-subtle-hovered': positiveFeedback,
          })}
          onClick={handlePositiveFeedback(true)}
          size="sm"
          variant="text"
        >
          <ThumbsUp />
          <div className="ml-1">{t`feedback.yes`}</div>
        </CWButton>
        <CWButton
          className={classNames('text-neutral normal-case', {
            'bg-primary-subtle-hovered': positiveFeedback === false,
          })}
          onClick={handlePositiveFeedback(false)}
          size="sm"
          variant="text"
        >
          <ThumbsDown />
          <div className="ml-1">{t`feedback.no`}</div>
        </CWButton>
      </div>
      {positiveFeedback != null ? (
        <FeedbackText
          identifier={identifier}
          onClose={handlePositiveFeedback(null)}
          positive={positiveFeedback}
          url={url}
        />
      ) : null}
    </div>
  )
}
